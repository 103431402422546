@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

@layer base {
  @font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(../src/assets/fonts/Inter/Inter-VariableFont_opsz\,wght.ttf) format("truetype");
  }
  body {
    @apply font-sans;
  }
}
div[aria-modal="true"] {
  z-index: 999998 !important; /* Lower than your modal */
}
